import React from "react"
import { navigate } from 'gatsby-link'
import { Link } from "gatsby"
import Header from "../components/header"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}



export default function Contact() {
    return (
        <div style={{ color: `teal` }}>
            <Link to="/">Home</Link>
            <Header headerText="Contact" />
            <p>Send us a message!</p>
        </div>
    )
}

// class Contact extends React.Component {
//   constructor(props) {
//     super(props)
//     this.state = { isValidated: false }
//   }

//   handleChange = event => {
//     this.setState({ [event.target.name]: event.target.value })
//   }

//   handleSubmit = event => {
//     event.preventDefault()
//     const form = event.target
//     fetch('/', {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//       body: encode({
//         'form-name': form.getAttribute('name'),
//         ...this.state,
//       }),
//     })
//       .then(() => navigate(form.getAttribute('action')))
//       .catch((error) => alert(error))
//   }

//   render() {
//     return (
//         <section className="section has-background-white">
//           <div className="container">
//             <div className="content has-text-centered">
//               <h1>Contact us</h1>
//               <p>Fill out the form or send an email to <a href="mailto:info@avlcloudtechnologies.com">info@avlcloudtechnologies.com</a>.</p>
//             </div>
//             <div className="columns is-centered">
//               <div className="column is-two-thirds">
//                <form 
//                 name="contact" 
//                 method="POST" 
//                 data-netlify="true"
//                 data-netlify-honeypot="bot-field"
//                 action="/"
//                 onSubmit={event => this.handleSubmit(event)}
//                >
//                 <input type="hidden" name="form-name" value="contact" />
//                 <div hidden>
//                   <label>
//                     Don’t fill this out if you are a human:{' '}
//                     <input name="bot-field" onChange={this.handleChange} />
//                   </label>
//                 </div>
//                 <div className="field is-horizontal">
//                   <div className="field-label is-normal">
//                     <label className="label">Name</label>
//                   </div>
//                   <div className="field-body">
//                     <div className="field">
//                        <p className="control">
//                         <input
//                           className="input" 
//                           type="text" 
//                           name="name"
//                           onChange={this.handleChange}
//                           placeholder="e.g John Smith"
//                           required={true}
//                         />
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//                 <div className="field is-horizontal">
//                   <div className="field-label">
//                   </div>
//                   <div className="field-body">
//                     <div className="field">
//                       <div className="control">
//                         <button className="button is-link is-rounded" type="submit">
//                           <strong>Send</strong>
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </form>
//               </div>
             
//             </div>
//           </div>
//         </section>
//     )
//   }
// }

  


// export default Contact